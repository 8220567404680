// 注册组件

import NoData from "./noData";
import Dialog from "./dialog";
import MainHeader from "./mainHeader";

export default {
  NoData,
  MainHeader,
  Dialog,
};
