import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import routes from "./route";
import store from "../store";

const router = createRouter({
  history: createWebHistory(), // createWebHashHistory(), //
  linkActiveClass: "is-active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// 路由拦截
router.beforeEach((to, from, next) => {
  console.log("路由拦截to: ", to);
  // 清除遗留数据
  store.commit("mFilterUpdata", {});
  if (to.meta && to.path) {
    next();
  } else {
    next("/404");
  }
});

router.afterEach(() => {});

export default router;

/*
 * 路由约定：
 * 1.公共页面没有name属性
 * 2.不需要权限控制的页面没有meta.code属性
 */
