// 基础路由映射
const routes = [
  {
    path: "/",
    component: () => import("@/views/intelick"),
    redirect: "/home",
    children: [
      // {
      //   // KYC认证
      //   name: "auth",
      //   path: "/",
      //   component: () => import("@/views/kYCIdentify/index"),
      //   meta: { noAuth: true, noCache: false },
      // },
    ],
  },

  {
    // intelick官网
    name: "intelick",
    path: "/home",
    component: () => import("@/views/intelick"),
    meta: { noAuth: true },
  },
  {
    // intelick官网
    name: "FAQs",
    path: "/FAQs",
    component: () => import("@/views/intelick/faqs"),
    meta: { noAuth: true },
  },
  {
    // intelick官网
    name: "Services",
    path: "/Services",
    component: () => import("@/views/intelick/service"),
    meta: { noAuth: true },
  },
  {
    // intelick官网
    name: "About",
    path: "/About",
    component: () => import("@/views/intelick/aboutus"),
    meta: { noAuth: true },
  },
  {
    // intelick官网
    name: "Contact",
    path: "/Contact",
    component: () => import("@/views/intelick/contact"),
    meta: { noAuth: true },
  },
  {
    // intelick官网
    name: "Blog",
    path: "/Blog",
    component: () => import("@/views/intelick/blog"),
    meta: { noAuth: true },
  },
  {
    // intelick官网
    name: "privacy",
    path: "/privacy",
    component: () => import("@/views/intelick/cPolicy"),
    meta: { noAuth: true },
  },

  {
    name: "404",
    path: "/:catchAll(.*)",
    component: () => import("@/views/404"),
    meta: { noAuth: true },
  },
];
export default routes;
